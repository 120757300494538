import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Profile = ({ coach }) => {
  const { image, lastName, firstName, rank, title, info } = coach
  return (
    <div className="profile">
      <div className="profile-container">
        <div
          className="profile-img"
          style={{ backgroundImage: `url(${image})` }}
        />
        <div className="profile-content">
          <div className="profile-content-inner">
            <p className="profile-title">
              {lastName} <strong>{firstName}</strong>
            </p>
            <span className="belt-rank">{rank}</span>
            <hr />
            <ul>
              <li>
                <FontAwesomeIcon icon="user" className="icon" size="sm" />
                {title}
              </li>
            </ul>
            {info && <p className="profile-info">{info}</p>}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Profile
