import React from 'react'

import Layout from '../components/layout'
import HomeScreen from '../components/Screens/HomeScreen'

const IndexPage = () => (
  <Layout title="Brazil Jiu Jitsu">
    <HomeScreen />
  </Layout>
)

export default IndexPage
