export const coaches = {
  organization: [
    {
      image: '../../../images/coaches/max-carvalho-coach.webp',
      lastName: 'Prof. Max',
      firstName: 'Carvalho',
      title: 'Zr Team Magyarország Alapító',
      rank: '3. Dan - Fekete öv',
      info: 'Prof. Zé Radiola tanítványa',
    },
    {
      image: '../../../images/coaches/ze-radiola-coach.webp',
      lastName: 'Prof. Zé',
      firstName: 'Radiola',
      title: 'Zr Team Alapító',
      rank: '4. Dan - Fekete öv',
      info: 'ZR Team iskola Alapítója. Carlos Gracie Jr. tanítványa',
    },
    {
      image: '../../../images/coaches/carlos-gracie-jr-coach.webp',
      lastName: 'Mestre Carlos',
      firstName: 'Gracie Jr.',
      title: 'Nagymester',
      rank: '7. Dan - Piros-Fekete öv',
      info: 'Zé Radiola Mestere',
    },
    {
      image: '../../../images/coaches/carlos-gracie-sr-coach.webp',
      lastName: 'Mestre Carlos',
      firstName: 'Gracie Sr.',
      title: 'BJJ Alapító Nagymester',
      rank: '10. Dan - Piros öv',
      info: 'A Brazil Jiu-Jitsu egyik alapító atyja. Carlos Gracie Jr. édesapja',
    },
  ],
  club: [
    {
      image: '../../../images/coaches/herceg-oliver-coach.webp',
      lastName: 'Herceg',
      firstName: 'Olivér',
      title: 'Vezetőedző',
      rank: 'Fekete öv',
      beltColor: '#9b59b6',
    },
    {
      image: '../../../images/coaches/gerenyi-tamas-coach.webp',
      lastName: 'Gerényi',
      firstName: 'Tamás',
      title: 'Segédedző',
      rank: 'Fekete öv',
      beltColor: '#9b59b6',
    },
  ],
}
