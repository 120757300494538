import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { useTranslation } from 'react-i18next'
import { StaticImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { coaches } from '../../constants/coaches'
import Profile from '../Profile/Profile'

const HomeScreen = () => {
  const { t } = useTranslation()
  return (
    <div className="HomeScreen">
      <section id="hero">
        <Container>
          <Row>
            <div className="hero-title">
              <h1>{t('pages.home.hero.title')}</h1>
              <p>{t('pages.home.hero.description')}</p>
              <ul className="hero-social-media">
                <li>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://facebook.com/zteambjjpecs"
                  >
                    <FontAwesomeIcon
                      icon={['fab', 'facebook']}
                      className="icon"
                      size="lg"
                    />
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.instagram.com/zrteampecs/"
                  >
                    <FontAwesomeIcon
                      icon={['fab', 'instagram']}
                      className="icon"
                      size="lg"
                    />
                  </a>
                </li>
                <li>
                  <a
                    href="tel:+36-70-679-0926"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon icon="phone" className="icon" size="lg" />
                  </a>
                </li>
              </ul>
              <Link to="#free-training" className="btn btn-main">
                {t('buttons.freeTraining')}
                <FontAwesomeIcon
                  icon="chevron-right"
                  size="lg"
                  className="icon"
                />
              </Link>
            </div>
          </Row>
        </Container>
      </section>

      <section id="about" className="section section--dark">
        <Container>
          <Row>
            <Col lg="6">
              <h2 className="title">{t('pages.home.about.title')}</h2>
              <p
                className="lead"
                dangerouslySetInnerHTML={{
                  __html: t('pages.home.about.content'),
                }}
              ></p>
              <Link to="/history" className="btn btn-main">
                {t('buttons.learnMore')}
                <FontAwesomeIcon
                  icon="chevron-right"
                  className="icon"
                  size="lg"
                />
              </Link>
            </Col>
            <Col lg="6">
              <StaticImage
                src="../../assets/images/bjj-advanced.webp"
                width={550}
                alt="bjj-advanced-gi-fighter"
              />
            </Col>
          </Row>
        </Container>
      </section>

      {/*       <section id="history" className="d-none d-sm-block">
        <Container>
          <Row className="justify-content-center">
            <Col md="auto">
              <h2 className="title">{t("pages.home.history.title")}</h2>
              <p
                className="lead history__content"
                dangerouslySetInnerHTML={{
                  __html: t("pages.home.history.content"),
                }}
              ></p>

              <Link to="/history" className="btn btn-main btn-inverse">
                <span>{t("buttons.more")}</span>
                <FontAwesomeIcon
                  icon="chevron-right"
                  className="icon"
                  size="lg"
                />
              </Link>
            </Col>
          </Row>
        </Container>
      </section> */}

      <section id="services" className="section">
        <Container>
          <Row>
            <Col>
              <h2 className="section-title text-center">Óráink</h2>
            </Col>
          </Row>
          <Row>
            <Col lg="4">
              <div className="service-card">
                <div className="service-card__header">
                  <StaticImage
                    src="../../assets/images/beginner-class.jpg"
                    width={420}
                    alt="beginner-class"
                  />
                </div>
                <div className="service-card__body">
                  <h4 className="service-card__title">Kezdő edzések</h4>
                  <p>
                    Kezdő edzéseinken a legalapvetőbb technikákkal és
                    stratégiákkal foglalkozunk. Az edzések felépítése a Brazil
                    Jiu Jitsu sokrétű technikáit alapmozdulatokba sűríti össze,
                    melyek...
                  </p>
                </div>
              </div>
            </Col>
            <Col lg="4">
              <div className="service-card">
                <div className="service-card__headaer">
                  <StaticImage
                    src="../../assets/images/advanced-class.png"
                    width={420}
                    alt="advanced-class"
                  />
                </div>
                <div className="service-card__body">
                  <h4 className="service-card__title">Haladó edzések</h4>
                  <p>
                    Haladó programunk alapját magasszintű, versenyedzett
                    technikák és gyakorlatok adják, melyek bármilyen szintű
                    tanuló számára kihívást és fejlődési lehetőséget
                    biztosítanak...
                  </p>
                </div>
              </div>
            </Col>
            <Col lg="4">
              <div className="service-card">
                <div className="service-card__header">
                  <StaticImage
                    src="../../assets/images/kids-class.jpg"
                    width={420}
                    alt="kids-class"
                  />
                </div>
                <div className="service-card__body">
                  <h4 className="service-card__title">Gyerek edzések</h4>
                  <p>
                    A ZR TEAM jövő bajnokai edzéseket azért hoztuk létre, hogy 3
                    és 15 év közötti gyermekekkel ismertethessük meg az olyan
                    alapértékeket, mint például a kitartás, összpontosítás...
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section id="trainers" className="section">
        <Container>
          <Row>
            <Col md="auto">
              <h2 className="title">{t('pages.home.coaches.title')}</h2>
            </Col>
          </Row>
          <Row>
            {coaches.club.map((coach, index) => (
              <Col lg="6" key={index}>
                <Profile coach={coach} />
              </Col>
            ))}
          </Row>
          <Row>
            <Col md="auto">
              <h2 className="title">{t('pages.home.coaches.mestre')}</h2>
              <Row>
                {coaches.organization.map((coach, index) => (
                  <Col lg="6" key={index}>
                    <Profile coach={coach} />
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  )
}

export default HomeScreen
